@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap')

$notebook: 'Source Code Pro', monospace
$blue: #7DBAF1
$yellow: #EFF5A3
$charcoal: #2D3142
$rose: #E84855
$green: #41521F

.selected
  background-color: $rose
  font-weight: bold

.hidden
  display: none

html
  background-color: $charcoal
  color: $blue

  body
    font-family: $notebook
    text-align: center
    max-width: 900px
    margin: auto

// Nav

nav
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 20px 0 0

  #logo-cont
    position: relative
  
    img#huell
      width: 100px
      transform: rotate(20deg)
      animation-name: huell-rotate
      animation-duration: 3s

    img#glasses
      width: 100px
      transform: rotate(15deg)
      position: absolute
      top: 54px
      right: 61px
      animation-name: glasses-drop
      animation-duration: 3s

    h1
      font-size: 30px


  ul
    display: flex
    width: 100%
    justify-content: center
    align-items: center
    padding: 0
    li
      list-style-type: none
      border: 1px solid $yellow
      width: 150px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
    a
      text-decoration: none
      color: $blue

// Shuffle Page

div#shuffle-page
  position: relative
  top: -90px

  img
    width: 125px
    position: relative
    top: 16px
    right: 13.5%
    animation-name: ford-drive
    animation-duration:4s

  #sub-option-cont
    display: flex
    justify-content: flex-end
    align-items: center
    position: relative
    top: -30px
    left: 0%
    animation-name: options-drive
    animation-duration: 4s

    .sub-option
      width: 125px
      border: 1px solid $yellow
      padding: 7px 0
      margin: 0 0 0 25px

  div.button-cont
    margin: 20px 0

  form
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    div
      width: 260px
      display: flex

  div#showFilter
    border: 1px solid $rose

// Key Frames
@keyframes huell-rotate
  0%
    transform: rotate(20deg)
  100%
    transform: rotate(380deg)

@keyframes glasses-drop
  0%
    top: -70px
  30%
    top: -70px
  100%
    top: 54px

@keyframes ford-drive
  0%
    right: -100%
  100%
    right: 13.5%

@keyframes options-drive
  0%
    left: 113.5%
  100%
    left: 0%